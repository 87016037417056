import styled from 'styled-components';
import Heading from '../Heading';
const H6 = styled(Heading).withConfig({
  displayName: "H6",
  componentId: "sc-11b5b2j-0"
})([""]);
H6.propTypes = Heading.propTypes;

/* eslint-disable-next-line hubspot-dev/no-default-props-assignment */
H6.defaultProps = Object.assign({}, Heading.defaultProps, {
  tagName: 'h6'
});
H6.displayName = 'H6';
export default H6;